/**
 * Styles for the Header.js component
 */

header {
  svg {
    position: absolute;
    right: -20%;
    top: 2px;
  }
}
