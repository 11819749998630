.html-body {
  * {
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", "Gotham-Book", sans-serif;
    white-space: normal !important;
    font-weight: 400;
    font-style: normal;
    text-align: left;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    font-weight: 700;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    display: block;
    word-break: break-word;
    word-wrap: break-word;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    line-height: 1.2857em;
    --x-height-multiplier: 0.363;
    --baseline-multiplier: 0.157;
    font-style: normal;
  }
  h1 {
    margin: 20px 0 20px -2.5px !important;
    font-size: 40px;
    margin-left: -2.5px;
    line-height: 1.04;
    letter-spacing: -0.028em;
  }
  h2 {
    margin: 15px 0 15px -1.69px !important;
    font-size: 27px;
    line-height: 1.22;
    letter-spacing: -0.022em;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
  }
  h3 {
    margin: 10px 0 10px -2px !important;
    font-size: 23px;
    line-height: 1.15;
    letter-spacing: -0.02em;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
  }
  h4 {
    margin: 5px 0 5px -1.5px !important;
    font-size: 20px;
    line-height: 1.22;
    letter-spacing: -0.018em;
    -webkit-margin-before: 1.33em;
    -webkit-margin-after: 1.33em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
  }
  p {
    margin-top: 15px;
    --x-height-multiplier: 0.35;
    --baseline-multiplier: 0.179;
    line-height: 1.53;
    letter-spacing: -0.004em;
    margin-bottom: 0;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    text-align: left;
    font-style: normal;
    font-size: 15px;
    &:first-of-type {
      margin-top: 18px;
    }
    span {
      line-height: inherit;
    }
    .fr-img-caption {
      display: flex;
      width: unset !important;
    }
    .fr-img-wrap {
      font-size: 13px;
      text-align: center;
    }
  }
  table {
    table-layout: fixed;
    th {
      font-weight: 700;
      text-align: center;
    }
  }
  img {
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 0.4s 0s;
    backface-visibility: hidden;
    z-index: 100;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    user-select: auto;
    width: 70% !important;
    height: auto;
    margin: auto;
    &.fr-bordered {
      width: calc(100% - (2 * 10px)) !important;
    }
  }
  iframe {
    width: 100% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  ol,
  ul {
    margin: 5px;
    padding-left: 30px;
    color: initial;
    font-size: 15px;
  }
  ol,
  ul {
    li {
      line-height: 1.63;
      letter-spacing: -0.004em;
      --x-height-multiplier: 0.35;
      --baseline-multiplier: 0.179;
      font-weight: 400;
      font-style: normal;
      padding-left: 10px;
      text-align: left;
    }
    pointer-events: auto !important;
  }
  ol {
    list-style: decimal;
    counter-reset: post;
  }
  ul {
    list-style: disc;
    font-style: normal;
  }
  em {
    font-style: italic;
  }

  i {
    font-feature-settings: "liga" on, "salt" on;
    font-style: italic;
  }
  b,
  strong {
    font-weight: 700;
  }
  hr {
    line-height: 1.4;
    display: block;
    text-align: center;
    box-sizing: content-box;
    height: 0;
    margin-bottom: 30px;
  }
  blockquote {
    padding: 0 0 2px 20px !important;
    border-left: 3px solid rgba(0, 0, 0, 0.8) !important;
    margin-top: 30px;
    p {
      padding: 0;
      margin: 0;
      font-size: 18px;
      line-height: 1.63;
      letter-spacing: -0.004em;
      --x-height-multiplier: 0.35;
      --baseline-multiplier: 0.179;
      font-weight: 400;
      font-style: italic;
    }
  }
  a {
    color: #2954a3;
    * {
      color: #2954a3;
    }
  }
}
