.nav-tabs {
  .nav-item {
    flex: 1;
    text-align: center;
    cursor: pointer;
    .nav-link {
      border-radius: 0;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
    }
  }
}
