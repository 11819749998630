.react-add-to-calendar {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  position: relative;
  display: inline-block;
  margin: 0 auto;
  width: inherit;

  &__wrapper {
    cursor: pointer;
    width: inherit;
  }

  &__button {
    padding: 6px 6px;
    border-radius: 3px;

    border: none;
    position: relative;
    background-color: transparent;
    display: inline-block;
    width: inherit;
    height: 100%;
  }

  &__dropdown {
    margin-top: 2px;
    position: absolute;
    left: 1px;
    width: 170px;
    padding-bottom: 8px;
    padding-top: 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    border: none;
    background-color: white;
    text-align: left;
    border-radius: 0;


    ul {
      list-style: none;
      margin: 0;
      padding: 0;


      li {
        a {
          padding: .25rem 1.5rem;
          color: #000;
          text-decoration: none;
          white-space: nowrap;
          display: block;

          &:hover{
            color: #16181b;
            text-decoration: none;
            background-color: #f8f9fa;
          }
        }
      }
    }
  }
}
