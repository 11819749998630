.group-card {
  &.simple-mode {
    padding: 5px 0px 5px 0px !important;
    height: 60px;
    padding-left: 20px;
    cursor: pointer;
    .sendbird-channel-preview__avatar,
    .sb-avatar .sb-avatar__text,
    .sb-avatar .sb-avatar__image {
      width: 45px !important;
      height: 45px !important;
    }
    .media-body {
      padding-top: 0 !important;
    }
  }
}
