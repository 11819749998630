.css-transition-enter {
  opacity: 0.01;
}

.css-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.css-transition-exit {
  opacity: 1;
}
.css-transition-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
