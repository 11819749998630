#left-side-bar-groups {
  overscroll-behavior: contain;
  .simple-mode {
    height: 40px;
    padding: 0 10px 10px 5px !important;
    background-color: transparent !important;
    .sb-avatar,
    .sb-avatar .sb-avatar__text,
    .sb-avatar .sb-avatar__image {
      width: 30px !important;
      height: 30px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .media-left {
      width: 30px;
    }
    .media-right {
      display: none;
    }
    .media-body {
      padding-top: 10px !important;
      h6 {
        font-size: 12.5px;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text-muted {
        display: none;
      }
      .badge {
        position: absolute;
        right: 0px;
        top: 5px;
      }
    }
  }
}

.left-column-col {
  @media screen and (min-width: 1200px) {
    visibility: visible !important;
    position: relative !important;
  }
  @media screen and (max-width: 1199px) {
    z-index: 200;
  }
}

#left-side-bar-members {
  overscroll-behavior: contain;
  padding: 0 5px;
  .searchbox {
    width: 100%;
  }
  .simple-mode {
    height: 40px;
    padding: 0 10px 10px 10px !important;
    margin-bottom: 10px;
    background-color: transparent !important;
    .sb-avatar,
    .sb-avatar .sb-avatar__text,
    .sb-avatar .sb-avatar__image {
      width: 30px !important;
      height: 30px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .media-left {
      width: 30px;
    }
    .media-right {
      display: none;
    }
    .media-body {
      > *:not(a) {
        display: none;
      }
      h6 {
        font-size: 12.5px;
      }
    }
  }
}
