.gf-column-left {
  overflow-y: visible !important;
}

.gf-column-right {
  overflow-x: visible !important;
  z-index: 110;
}

.chat-font-size {
  font-size: 0.9rem;
}

.channel-active {
  border-left: 4px solid $primary;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(41, 84, 163, 0.2);
  padding-left: 30px;
}

.chat-separator {
  border: none;
  height: 1px;
  display: inline-block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
}

.chat-uploading-indicator {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1000;
}

.simple-infinite-scroll {
  overflow-y: scroll;
}

.circle:before {
  content: " \25CF";
  font-size: 15px;
}
.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__lower {
  width: 209px;
}

.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper {
  width: 209px;
}

.sendbird-message-input {
  .mention-input__input {
    border-radius: 0;
    border: 1px solid #dddddd;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  #btn-send-chat {
    border-radius: 0;
    width: 60px;
    margin: 5px 5px 5px;
  }
}

.sendbird-thumbnail__wrap {
  .sendbird-thumbnail__img {
    height: 200px !important;
    width: auto !important;
    @media screen and (max-width: 480px) {
      max-width: 280px !important;
    }
  }
}

.sendbird-user-message--incoming .sendbird-user-message__avatar,
.sendbird-thumbnail__avatar.sendbird-avatar {
  bottom: auto;
  margin-top: 3px;
}
.sendbird-message--incoming.sendbird-message,
.sendbird-message--outgoing.sendbird-message {
  margin-top: 15px;
}
.sendbird-file-message--icoming {
  margin-bottom: 15px;
}
.chat-read-receipts {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.sendbird-channel-list {
  box-shadow: 0 9px 15px -7px rgba(0, 0, 0, 0.04),
    0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 24px 38px 3px rgba(0, 0, 0, 0.12);
}
.chat-event-details-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0 20px 0;
}

.sendbird-user-message--outgoing .sendbird-user-message__more {
  left: -40px;
  top: -4px;
}

.emoji-mart {
  @media screen and (max-width: 480px) {
    left: -15px;
    .emoji-mart-bar {
      display: none;
    }
  }
}

.right-column-visibility-button {
  opacity: 0.6;
  position: fixed;
  top: 114px;
  right: -8px;
  z-index: 3000;
  transform: rotate(-90deg);
  @media screen and (max-width: 899px) {
    visibility: visible !important;
  }
}

.button-right-icon-arrow {
  right: -1px;
  top: 120px;
}

.left-column-visibility-button {
  opacity: 0.6;
  position: fixed;
  top: 190px;
  right: -29px;
  z-index: 3000;
  transform: rotate(-90deg);
  @media screen and (max-width: 1199px) {
    visibility: visible !important;
  }
}

.button-left-icon-arrow {
  right: -1px;
  top: 163px;
}

.sendbird-app__settingspanel-wrap {
  transition: visibility 0.3s linear;

  @media screen and (min-width: 900px) {
    visibility: visible !important;
  }
  @media screen and (max-width: 899px) {
    height: auto;
  }
}

.sendbird-channel-list {
  transition: visibility 0.3s linear;
  @media screen and (min-width: 1200px) {
    visibility: visible !important;
  }
}

.chat-header-sticky-navbar {
  z-index: 100 !important;
}

.sendbird-channel-profile__edit {
  @media screen and (max-width: 899px) {
    margin-right: 20px;
  }
}

.sendbird-msg-hoc {
  .emoji-mart {
    @media screen and (max-width: 480px) {
      left: 10px;
      bottom: 100px !important;
    }
  }
  .video-external {
    height: auto !important;
  }
}
