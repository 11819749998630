#right-side-bar-chat {
  overscroll-behavior: contain;
  .simple-mode {
    padding: 15px 0px 15px 0px !important;
    height: 60px;
    padding-left: 20px;
    .sendbird-channel-preview__avatar,
    .sb-avatar .sb-avatar__text,
    .sb-avatar .sb-avatar__image {
      width: 35px !important;
      height: 35px !important;
    }
    .sendbird-channel-preview__content {
      padding: 0px !important;
      margin: 0px !important;
      .sendbird-channel-preview__content__lower {
        margin-top: 5px;
      }
      .sendbird-channel-preview__content__upper__header {
        margin-left: 20px;
      }
      .sendbird-label {
        font-size: 12px;
        white-space: nowrap;
      }
      .sendbird-channel-preview__content__lower__last-message {
        font-size: 11px;
        margin-left: 20px;
      }
      .sendbird-channel-preview__content__upper__last-message-at {
        font-size: 10px;
      }
      .sendbird-channel-preview__content__lower__unread-message-count {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    .sendbird-channel-preview__action {
      display: none;
    }
  }
  .sendbird-channel-list {
    box-shadow: none !important;
  }
}

.chat-pop-up {
  width: 370px;
  height: 60%;
  position: fixed;
  right: 10px;
  bottom: 10px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  .chat-pop-up-header {
    position: relative;
    height: 50px;
    h6 {
      margin: 14px;
    }
    button {
      position: absolute;
      right: 5px;
      top: 5px;
      i {
        font-weight: 700;
      }
    }
  }
  .chat-pop-up-body {
    height: calc(100% - 50px);
    padding-left: 15px;
    padding-right: 15px;
    #chat-converstation {
      height: 100%;
      #chatConversationChatHeader {
        display: none;
      }
      #chat-converstation-invert-scroll {
        height: calc(100% - 100px) !important;
        overflow-x: hidden;
        overscroll-behavior: contain;
        padding-left: 0px !important;
        padding-right: 0px !important;

        .sendbird-thumbnail .sendbird-thumbnail__video,
        .sendbird-thumbnail .sendbird-thumbnail__img {
          max-width: 280px;
          max-height: 280px;
        }
        .emoji-mart-bar {
          display: none;
        }
        .sendbird-user-message--outgoing {
          max-width: 306px;
        }
      }

      #chatConversationChatInput {
        margin-left: -15px;
        .emoji-mart {
          right: 0px !important;
          .emoji-mart-bar {
            display: none;
          }
        }
        #btn-send-chat {
          margin-right: 5px !important;
        }
        .btn-group {
          margin-right: 40px !important;
        }
        .mention-input {
          width: 255px !important;
        }
      }
      .chat-uploading-indicator {
        right: -15px;
        left: -15px;
      }
    }
  }
}

.right-column-col {
  @media screen and (min-width: 900px) {
    visibility: visible !important;
  }
  @media screen and (max-width: 899px) {
    z-index: 199;
  }
}

.right-side-bar-notifications {
  overscroll-behavior: contain;
}
