#left-side-bar-members,
#left-side-bar-groups,
#right-side-bar-chat,
.right-side-bar-notifications {
  overflow: auto;
  scrollbar-color: transparent transparent;
  &:hover {
    scrollbar-color: auto;
    &::-webkit-scrollbar-thumb {
      color: #666666ff;
      background-clip: content-box;
      border: 1px solid transparent;
      border-radius: 7px;
      box-shadow: inset 0 0 0 10px;
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
}
