.search-filter-member-bar {
  position: relative;
  .searchbox {
    width: calc(100% - 150px);
  }
  .filter-select {
    width: calc(100% - 80px);
  }
  .filter-wrapper {
    .filter-map-nav {
      position: absolute;
      right: 0;
      top: 0;

      #membersMapBtn,
      #membersFiltersBtn {
        padding-top: 7px;
        padding-bottom: 9px;
        width: 70px;
      }
    }
  }
  .rounder {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
  }
}

.member-detail-sticky-navbar {
  z-index: 100 !important;
}
