// Override Bootstrap's variables before importing

// Colors
$primary: #2954a3;
$body-bg: #f1f3f7;
$light: #f5f7fa;
$body-color: #0d0d0d;
$link-color: #0d0d0d;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 900px,
  xl: 1200px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px,
);

$navbar-dark-color: white;
$navbar-dark-hover-color: white;

// $dropdown-link-color: #0d0d0d;

$card-border-width: 0;

// Jumbotron
$jumbotron-bg: white;

// Misc
//$border-radius: 0;

// Type
$font-family-sans-serif: "Inter UI", sans-serif;
$headings-margin-bottom: 0.5em;

$modal-content-border-width: 0;

// Then import Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

.bg-bg {
  background-color: $body-bg;
}

.rounded-xl {
  border-radius: 0.5rem !important;
}

.btn {
  &:focus,
  &.focus {
    box-shadow: none;
  }
}
