$event-bg: #2954a3 !default;

@import "../../../../node_modules/react-big-calendar/lib/sass/styles";

.rbc-calendar {
  height: 500px;
}

.rbc-month-view {
  background-color: white;
}
/**@TODO fix border color on focus */




