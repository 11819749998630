.mentions-suggestions {
  // border-radius: 0.25rem;
  background-color: #f1f3f7;
}

//@FIXME bad margins after resizing textarea
.mention-input__suggestions {
  background-color: transparent;
  max-height: 200px;
  overflow: auto;
  margin-left: 6px;
  margin-top: 18px !important;
}

.mention-input__suggestions__list {
  font-size: smaller;
  max-width: 300px;
}

//Override bootstrap
.mention-input__input {
  display: block;
  width: 100% !important;
  height: 100%;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: none;
  overflow: auto !important;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}
// mentions loading spinner
.mentions-spinner {
  position: absolute;
}
// comments
.comment-input .mentions-spinner {
  right: 75px;
  bottom: -2px;
}
// edit comments, new edit post
.comment-card,
.post-new-edit-body-input {
  position: relative;
  .mentions-spinner {
    right: 30px;
    bottom: 5px;
  }
}
// chat
.sendbird-message-input .mentions-spinner {
  right: 25px;
  bottom: 8px;
}
// edit chat
.chat-edit-message {
  position: relative;
  .mentions-spinner {
    right: 30px;
    bottom: 75px;
  }
}

.mention-input__highlighter {
  overflow-wrap: anywhere !important;
}
