.event-card {
  @media screen and (max-width: 568px) {
    .event-card-attendees {
      display: none;
    }
    .event-date-time {
      margin-bottom: 2px;
    }
    .event-fee {
      margin-top: 4px !important;
    }

    .rsvp-button-col {
      justify-content: start !important;
      .rsvp-button {
        margin-top: 10px !important;
        padding: 1px;
        .rsvp-button-text {
          font-size: smaller !important;
          margin: 0 auto;
        }
      }
    }
  }
}

.event-list-filters-row {
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
