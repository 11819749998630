@import "bootstrap";
@import "fonts";
@import "components/add_event_to_calendar";
@import "components/announcement";
@import "components/chat";
@import "components/css_transition";
@import "components/datepicker";
@import "components/drag_and_drop";
@import "components/event";
@import "components/footer";
@import "components/group";
@import "components/header";
@import "components/left_side_bar";
@import "components/loading";
@import "components/material-icons";
@import "components/member";
@import "components/mentions";
@import "components/post";
@import "components/rbc";
@import "components/recipes";
@import "components/right_side_bar";
@import "components/scroll";
@import "components/tab";
@import "components/video";
@import "components/accordion";
@import "components/HTML";

$navbar-height: 54px;
$navbar-brand-image-height: 50px;
$btn-hover-background-color: #dce3ed;
$btn-hover-border-color: #d3dce9;
$anchor-color: #2954a3;

body {
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", "Gotham-Book", sans-serif;
  }
  overflow-y: scroll;
  width: 100%;
  @media screen and (max-width: 360px) {
    overflow-x: hidden;
  }
}

body,
html,
#root {
  height: 100%;
}

.anchor-color {
  color: $anchor-color;
}
a[target="_blank"] {
  color: $anchor-color;
}
/**
 * Text utilities extention
 */
.text-underline {
  text-decoration: underline;
}
/**
 * Text utilities extention
 */
.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

/**
 * Cursor utilities
 */
.cursor-pointer {
  cursor: pointer;
}

/**
 * Form Overrides
 */
.form-control,
.mention-input__input {
  &:focus {
    outline: none !important;
    box-shadow: none;
    border-color: $gray-400;
    border-bottom-color: $primary;
    &::placeholder {
      opacity: 0.3;
    }
  }
}

.btn {
  i {
    font-size: 0.8rem;
  }
}

.btn-light:hover {
  background-color: $btn-hover-background-color;
}

.comment-send-button {
  border-radius: 0 0 0.25rem 0.25rem;
}

.pointer {
  cursor: pointer;
}

/**
 * Pulsating Loading Animation
 */
.content-loading {
  opacity: 1;
  animation: fadeInOut 1s linear;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.7;
  }
}

.navbar {
  min-height: $navbar-height;

  .navbar-container {
    height: $navbar-height;
    overflow-x: auto;
    width: 100%;

    display: flex;

    @media screen and (min-width: 768px) {
      width: auto;
    }

    @media screen and (min-width: 1200px) {
      width: 300px;
    }
  }

  @media screen and (min-width: 768px) {
    height: $navbar-height;
  }
}
/**@FIXME fix navbar border colors, theming needs to be implemented*/
.navbar-brand {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 1.5rem;
  img {
    min-width: $navbar-height;
    max-width: 300px;
    height: $navbar-brand-image-height;
    border-radius: 10px;
  }
}
.navbar-nav {
  @media screen and (min-width: 768px) {
    width: 100%;
    .navbar-main-features {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
    }
    #userDivider {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    max-height: 500px;
    overflow-y: scroll;
  }

  .nav-item {
    .nav-link {
      display: flex;
      border-left: 3px solid $primary;
      padding-left: 1rem;
      padding-right: 1rem;

      i {
        display: flex;
        align-items: center;
        opacity: 0.5;
      }

      &.active,
      &:hover {
        border-left: 3px solid $white !important;

        i {
          opacity: 1;
        }
      }

      @media screen and (min-width: 768px) {
        border-left: 0;
        border-bottom: 3px solid $primary;
        height: $navbar-height;

        &.active,
        &:hover {
          border-left: 0 !important;
          border-bottom: 3px solid $white !important;
        }
      }
    }
  }
}

.navbar-right-column {
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (min-width: 1200px) {
    width: 300px;
  }
}

/**
 * Thesea are copied and customized styles from bootstrap drowpown
 * but implemented only for md+ breakpoints
 */
.navbar-dropdown {
  @media screen and (min-width: 768px) {
    position: relative;

    .viewing-as-guest-dropdown-alert {
      margin-bottom: 5px;
      padding-bottom: 12px;
      padding-top: 12px;
      font-size: small;
    }

    .navbar-dropdown-menu {
      position: absolute;
      top: 100%;
      z-index: $zindex-dropdown;
      display: none; // none by default, but block on "open" of the menu
      float: left;
      min-width: 270px;
      max-height: 600px;
      overflow-x: auto;
      text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
      list-style: none;
      background-color: $dropdown-bg;
      background-clip: padding-box;
      border: $dropdown-border-width solid $dropdown-border-color;
      @include border-radius($dropdown-border-radius);
      @include box-shadow($dropdown-box-shadow);

      &.show {
        display: block;
      }

      .nav-link {
        color: $primary;

        &.active,
        &:hover {
          color: $primary;
        }

        @media screen and (min-width: 768px) {
          border-left: 3px solid $white;
          border-bottom: 0;

          &.active,
          &:hover {
            border-left: 3px solid $white !important;
            border-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.navbar-dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}

.navbar-dropdown-toggle {
  display: flex;
  align-items: center;

  &::after {
    margin-left: 1rem;
  }
}

.sticky-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: $navbar-height;
  z-index: 1020;
}

.text-small {
  font-size: 12px;
}

.gf-column {
  width: 300px;
  position: fixed;
  top: $navbar-height;
  bottom: 0;

  &-left {
    @extend .gf-column;
    left: 0;
  }
  &-right {
    @extend .gf-column;
    right: 0;
  }
}

.gf-main-content {
  min-width: 350px;
  max-width: 600px;
  margin-top: $navbar-height;
  margin-left: auto;
  margin-right: auto;
  &-preview {
    @extend .gf-main-content;
    margin-top: 0;
  }
}

/**
 *Images grid for posts
 */

.ReactModal__Body--open {
  overflow: hidden;
}

.grid-container {
  text-align: center;
  margin: auto;
  width: 100%;
}

.post-container {
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
}

.post-container * {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.border {
  border: 2px solid white;
}

.background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.height-one {
  width: 100%;
  padding-top: 100%;
}

.height-two {
  width: 50%;
  padding-top: 50%;
}

.height-three {
  width: 33.3333%;
  padding-top: 33.3333%;
}

.cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 6px;
}

.cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 7%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.cover-text > p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  height: 0;
  bottom: 100%;
  transition: 0.5s ease;
  overflow: hidden;
  font-size: 3%;
}

.border:hover .slide {
  bottom: 0;
  height: auto;
}

.border:hover .animate-text {
  top: 62%;
}

//Calendar
.rbc-toolbar {
  & button {
    background: $light;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      outline: 0;
      color: #373a3c;
      background-color: transparent;
      border-color: transparent;
    }
    &:hover {
      border-color: $btn-hover-border-color;
      background-color: $btn-hover-background-color;
    }
    &:active {
      box-shadow: none;
      background-color: transparent;
      border-color: transparent;
    }
    &:focus:active {
      box-shadow: none;
      background-color: $btn-hover-border-color;
      border-color: transparent;
    }
  }
}

// Dot

.dot {
  height: 10px;
  width: 10px;
  background-color: $danger;
  border-radius: 50%;
  display: inline-block;
}

//Reactions
.reactions-button {
  position: relative;
}
.reactions-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 35px;
  transition: visibility 0s, opacity 0.3s;
  transition-delay: 0.5s;
}
.reactions-button:hover .reactions-content {
  visibility: visible;
  opacity: 1;
}

.modal__seenby,
.modal__reactby {
  .modal-header {
    .modal-title {
      font-weight: normal;
    }

    .close {
      color: white;
      opacity: 0.8;

      position: absolute;
      right: 1rem;

      &:not(:disabled):not(.disabled) {
        @include hover-focus {
          opacity: 1;
        }
      }
    }
  }
}

.toast-hide {
  display: none;
}

.main-content-col {
  @media screen and (max-width: 400px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.breadcrumb {
  padding-left: 0;
}
