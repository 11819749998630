.dragging-box {
  border: dashed grey 1px;
  background-color: rgba(255,255,255,.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.dragging-message {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
}